{
  "name": "wormscan-ui",
  "version": "1.3.8",
  "private": true,
  "source": "src/index.html",
  "scripts": {
    "build": "parcel build --no-scope-hoist --public-url ./ && node addCSP.ts",
    "start": "parcel",
    "clean": "rimraf dist && rimraf .parcel-cache",
    "prepare": "husky install",
    "test:e2e": "yarn playwright test"
  },
  "devDependencies": {
    "@parcel/transformer-sass": "^2.8.3",
    "@playwright/test": "^1.38.1",
    "@types/node": "^18.13.0",
    "@types/react": "^18.0.27",
    "@types/react-dom": "^18.0.10",
    "@types/react-table": "^7.7.14",
    "@typescript-eslint/eslint-plugin": "latest",
    "@typescript-eslint/parser": "latest",
    "assert": "^2.0.0",
    "crypto-browserify": "^3.12.0",
    "eslint": "^8.41.0",
    "eslint-plugin-react": "latest",
    "eslint-plugin-react-hooks": "^4.6.0",
    "events": "^3.1.0",
    "http-proxy-middleware": "^2.0.6",
    "https-browserify": "^1.0.0",
    "husky": ">=6",
    "lint-staged": ">=10",
    "parcel": "^2.8.3",
    "prettier": "^2.8.3",
    "process": "^0.11.10",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "rimraf": "^4.1.2",
    "stream-browserify": "^3.0.0",
    "stream-http": "^3.1.0",
    "string_decoder": "^1.3.0",
    "typescript": "^4.9.4",
    "url": "^0.11.0"
  },
  "dependencies": {
    "@analytics/google-analytics": "^1.0.7",
    "@certusone/wormhole-sdk": "latest",
    "@metamask/detect-provider": "^2.0.0",
    "@radix-ui/react-icons": "1.1.1",
    "@radix-ui/react-navigation-menu": "^1.1.4",
    "@radix-ui/react-switch": "^1.0.3",
    "@radix-ui/react-toggle-group": "1.0.2",
    "@radix-ui/react-tooltip": "1.0.5",
    "analytics": "^0.8.9",
    "apexcharts": "3.36.0",
    "axios": "^1.4.0",
    "i18next": "22.4.9",
    "react": "18.2.0",
    "react-apexcharts": "1.4.0",
    "react-dom": "18.2.0",
    "react-draggable": "4.4.5",
    "react-i18next": "12.1.5",
    "react-json-view-lite": "^1.1.0",
    "react-query": "3.39.3",
    "react-router-dom": "6.22.0",
    "react-select": "5.7.2",
    "react-table": "7.8.0",
    "react-toastify": "^10.0.4",
    "recoil": "0.7.6"
  },
  "alias": {
    "src": "./src",
    "@injectivelabs/sdk-ts": false,
    "@injectivelabs/networks": false,
    "@injectivelabs/utils": false,
    "@terra-money/terra.js": false,
    "@terra-money/terra.proto": false,
    "@terra-money/legacy.proto": false,
    "@xpla/xpla.js": false,
    "aptos": false,
    "@mysten/sui.js": false,
    "near-api-js": false,
    "@project-serum/anchor": false,
    "lodash": false,
    "elliptic": false
  },
  "lint-staged": {
    "*.{svg,ts,tsx,js,jsx,scss,css,md}": "prettier --write"
  },
  "packageManager": "yarn@3.5.0"
}
